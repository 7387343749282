<template>
  <div>
    <div class="container-fluid">
      <div class="card border-0 shadow-none card-margin">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-4">
              <h4>Add new post</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="row mb-3">
                <div class="col">
                  <label for="">Post title</label>
                  <input
                    type="text"
                    v-model="post.title"
                    @input="titleUpdate"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for="">Slug</label>
                  <input
                    type="text"
                    v-model="post.slug"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Author</label>
                  <select v-model="post.author_id" class="form-control">
                    <option v-for="a in authors" :value="a.id" :key="a.id">
                      {{ (a.first_name + ' ' + a.last_name) }}
                    </option>
                  </select>
                </div>

                <div class="col">
                  <label for="">Category</label>
                  <select v-model="post.category_id" class="form-control">
                    <option v-for="c in categories" :value="c.id" :key="c.id">
                      {{ c.title }}
                    </option>
                  </select>
                </div>
              </div>

              <vue-wysiwyg-editor v-model="post.content" class="mb-4"
                @input="updateSeoDescription" :disableFullScreen="false"></vue-wysiwyg-editor>

              <div class="row mb-4">
                <div class="col">
                  <div>
                    <label for="" class="mb-3">Featured image</label>
                    <feature-image-upload
                      v-model="post.featured_image"
                      v-bind:image="post.featured_image"
                      @unsetFeaturedImg="unsetFeaturedImg"
                    ></feature-image-upload>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <a @click="showHideSeoOpt" class="option-link">
                    <span v-if="seo_opt_show">-</span>
                    <span v-else>+</span> SEO options
                  </a>
                </div>
              </div>

              <div class="row" v-if="seo_opt_show">
                <div class="col">
                  <div class="mb-3">
                    <label for="">SEO title</label>
                    <input
                      type="text"
                      v-model="post.seo_title"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-4">
                    <label for="">SEO Description ({{ seo_desc_left }} left)</label>
                    <textarea
                      rows="3"
                      v-model="post.seo_description"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <a @click="showHideAdvOpt" class="option-link">
                    <span v-if="adv_opt_show">-</span>
                    <span v-else>+</span> Advanced options
                  </a>
                </div>
              </div>

              <div class="row" v-if="adv_opt_show">
                <div class="col">
                  <div class="mb-3">
                    <label for="">Embedded scripts</label>
                    <textarea
                      rows="3"
                      v-model="post.embedded_scripts"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-4">
                    <label for="">Ad id</label>
                    <input
                      type="text"
                      v-model="post.ad_id"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-3">
                    <input type="checkbox" v-model="post.featured">
                    <label for="">Set featured</label>
                  </div>

                  <div class="mb-4">
                    <input type="checkbox" v-model="post.show_trusted_message">
                    <label for="">Show trusted message</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed bottom-0 mb-3 start-0 ms-2">
        <button
          class="btn btn-success btn-lg d-block mb-4"
          v-tooltip.right="'Save changes to the post'"
          @click="createPost"
        >
          <i class="far fa-save"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import VueWysiwygEditor from "../../components/editor/Vue-Wysiwyg-Editor";
import FeatureImageUpload from "../../components/editor/partials/FeatureImageUpload";

export default {
  data() {
    return {
      // Post data
      post: {
        id: null,
        slug: "",
        author_id: null,
        category_id: null,
        title: "",
        content: "",
        embedded_scripts: "",
        seo_title: "",
        seo_description: "",
        featured_image: "",
        featured: 0,
        status: "draft",
        ad_id: 0,
        show_trusted_message: 0,
      },
      // Additional API requests
      authors: [],
      categories: [],
      // Various display vars
      adv_opt_show: 0,
      seo_opt_show: 0,
      seo_desc_max_len: 200,
      seo_desc_left: 200,
    };
  },

  watch: {
    post: {
      handler: function (val, oldVal) {
        if (val.seo_description != "") {
          this.seo_desc_left = (this.seo_desc_max_len - this.post.seo_description.length);
        } else {
          this.seo_desc_left = this.seo_desc_max_len;
        }
      },
      deep: true,
    },
  },
  methods: {
    createPost() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/posts", this.post)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);

          if (!data.color || data.color !== "danger") {
            this.$router.push("/posts/" + data.post_id);
          }
        });
    },

    getAuthors() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/posts/authors")
        .then(({ data }) => {
          this.authors = data;
        });
    },

    getCategories() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/posts/categories")
        .then(({ data }) => {
          this.categories = data;
        });
    },

    showHideAdvOpt() {
      this.adv_opt_show = !this.adv_opt_show ? 1 : 0;
    },

    showHideSeoOpt() {
      this.seo_opt_show = !this.seo_opt_show ? 1 : 0;
    },

    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, "");

      // Make the string lowercase
      str = str.toLowerCase();

      // Remove accents, swap ñ for n, etc
      var from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to =
        "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      // Remove invalid chars
      str = str
        .replace(/[^a-z0-9 -]/g, "")
        // Collapse whitespace and replace by -
        .replace(/\s+/g, "-")
        // Collapse dashes
        .replace(/-+/g, "-");

      return str;
    },

    titleUpdate() {
      if (this.post.title != "") {
        this.post.seo_title = this.post.title;
        this.post.slug = this.slugify(this.post.title);
      }
    },

    unsetFeaturedImg() {
      this.post.featured_image = null
    },

    updateSeoDescription(e) {
      if (this.post.content != "") {
        // Meta-description shouldn't take HTML
        let tmpEle = document.createElement("div");
        tmpEle.innerHTML = this.post.content;

        this.post.seo_description = tmpEle.innerText.substr(0, this.seo_desc_max_len);
      }
    },
  },
  created() {},
  mounted() {
    this.$store.sidebarOpen = false;

    this.getAuthors();
    this.getCategories();
  },
  components: {
    Loading,
    VueWysiwygEditor,
    FeatureImageUpload
  },
};
</script>

<style>
  .card-margin {
    margin: 0 2rem;
  }

  div.ProseMirror {
    min-height: 250px;
  }

  .option-link {
    margin-right: 10px;
    text-decoration: underline;
  }

  input[type=checkbox] {
    margin-right: 10px;
  }

  span[id] {
    text-decoration:underline;
    text-decoration-style: dotted;
  }

  ul {
    display: block;
    list-style-type: disc;
    padding-inline-start: 20px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
</style>